<template>
    <div>
        <app-layout>
            <template v-slot:header>
                <Header :title="$t('dashboard')" :isFilter="false">
                </Header>
            </template>
            <template v-slot:header-mobile>
                <HeaderMobile :title="$t('dashboard')" :isFilter="false">
                </HeaderMobile>
            </template>
            <div v-if="isStudent">
                <div class="col-10" v-show="user.active_student_program?.active_semester_status != '101'">
                    <AlertComponent :user="user.active_student_program?.active_semester_status" />
                </div>
                <div class="col-10" v-show="user.active_student_program?.nationality_code != 'TR'
                    && user.active_student_program?.active_semester_status != '101'">
                    <ForeignStudentWarning :user="user.active_student_program?.active_semester_status" />
                </div>
            </div>
            <div v-if="isStudent || isOfficial">

                <div class="d-flex flex-column flex-lg-row justify-content-between">
                    <div class="flex-grow-1 mr-0 mr-md-5">
                        <div class="row" v-if="checkDoubleMajorStatus">
                            <div class="col-12">
                                <b-alert variant="info" show dismissible>
                                    <div v-html="doubleMajorText">

                                    </div>
                                </b-alert>
                            </div>
                        </div>

                        <h5>{{ $t('fast_transactions') }}</h5>
                        <div class="border rounded p-4">
                            <b-row>
                                <b-col cols="12" sm="6" md="4" lg="3" v-for="(menu, menuKey) in menus"
                                    :key="'menu' + menuKey">
                                    <div class="bg-light rounded p-4 mb-4 cursor-pointer" @click="goToUrl(menu.url)">
                                        {{ getLocaleText(menu, 'name').toUpper() }}
                                    </div>
                                </b-col>
                                <b-col cols="12" sm="6" md="4" lg="3" v-for="(button, index) in applicationButtons"
                                    :key="index">
                                    <a :href="button.url" :target="button.target">
                                        <div class="bg-light rounded p-4 mb-4 cursor-pointer">
                                            {{ getLocaleText(button, 'name').toUpper() }}
                                        </div>
                                    </a>
                                </b-col>
                            </b-row>
                        </div>
                    </div>
                    <div style="min-width: 300px">
                        <h5>{{ $t('help') }}</h5>
                        <div class="border rounded p-4 pt-5 pb-5 bg-light">
                            <a href="https://bau.edu.tr/help" target="_blank">
                                <div class="d-flex align-items-center font-size-16 mb-2 btn-link cursor-pointer">
                                    <i class="ri-question-line mr-2"></i> {{ $t('contact_support') }}
                                </div>
                            </a>
                            <div class="d-flex align-items-center font-size-16 btn-link cursor-pointer"
                                @click="getUserGuide">
                                <i class="ri-book-open-line mr-2"></i> {{ $t('user_quide') }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <CommonModal ref="kvkk1Modal" size="xl" :closeBtn="false" :onHideOnlyX="true">
                <template v-slot:CommonModalTitle>
                    <div>{{ $t('statement_3').toUpper() }}</div>
                </template>
                <template v-slot:CommonModalContent>
                    <div>
                        <div class="row">
                            <div v-html="kvkkChosenContent" class="col-12">
                            </div>
                        </div>
                        <b-button class="btn--primary" @click="continueProcess">{{
                    $t('read_and_accept')
                            }}
                        </b-button>
                    </div>
                </template>
            </CommonModal>
        </app-layout>
    </div>
</template>
<script>
// Template
import AppLayout from '@/layouts/AppLayout'
import Header from '@/layouts/AppLayout/Header'
import HeaderMobile from '@/layouts/AppLayout/HeaderMobile'

// Component
import PasswordInput from '@/components/elements/PasswordInput'
import PasswordInputWithStrength from '@/components/elements/PasswordInputWithStrength'
import CommonModal from "@/components/elements/CommonModal";
import AlertComponent from "@/components/elements/AlertComponent.vue";


// Services
import DashboardService from '@/services/DashboardService'
import StudentProgramService from "@/services/StudentProgramService";
import StudentService from "@/services/StudentService";
import Base from '@/plugins/Base'
import ConstantService from "@/services/ConstantService"

//Other
import qs from "qs";
import ForeignStudentWarning from "@/components/page/ForeignStudentWarning.vue";

export default {
    name: "DashboardPage",
    components: {
        ForeignStudentWarning,
        AlertComponent,
        AppLayout,
        Header,
        HeaderMobile,
        PasswordInput,
        PasswordInputWithStrength,
        CommonModal
    },
    metaInfo() {
        return {
            title: this.$t('dashboard')
        }
    },
    data() {
        return {
            userStore: {},
            kvkkChosenContent: null,
            applicationButtons: [],
            doubleMajorText: null
        }
    },
    created() {
        this.kvkkCheck()
        this.$store.dispatch("dashboard/getAppImages");
        //this.getStudentApplicationStatus()
    },
    computed: {
        user() {
            return this.$store.getters['auth/getUser'];
        },
        menus() {
            let menus = this.$store.getters['auth/getMenus'];
            let data = []
            if (menus && menus.length > 0) {
                menus.forEach((menu) => {
                    if (menu.items && menu.items.length) {
                        menu.items.forEach((item) => {
                            data.push(item)
                        })
                    }
                })
            }
            return data
        },
        isAdmin() {
            return this.$store.getters['auth/isAdmin'];
        },
        isStudent() {
            return this.$store.getters['auth/isStudent'];
        },
        isOfficial() {
            return this.$store.getters['auth/isOfficial'];
        },
        checkDoubleMajorStatus() {
            if (this.user?.active_student_program?.major_type == 'C') {
                let result = []
                this.user.student.student_program.forEach(itm => {
                    if (itm.major_type == 'A' && ['112', '122', '121'].includes(itm.last_semester_status)) {
                        result.push(itm)
                    }
                })

                if (result.length > 0) {
                    const key = this.$i18n.locale == 'tr' ? "double_major_minor_semester_status_warning_text_tr" : "double_major_minor_semester_status_warning_text_en"
                    this.ConstantServiceGetValue(key)
                    return true
                } else {
                    return false
                }
            } else if (this.user?.active_student_program?.major_type == 'A') {
                let result = []
                this.user.student.student_program.forEach(itm => {
                    if (['112', '122', '121'].includes(itm.last_semester_status)) {
                        result.push(itm)
                    }
                })

                if (result.length > 0) {
                    const key = this.$i18n.locale == 'tr' ? "major_semester_status_warning_text_tr" : "major_semester_status_warning_text_en"
                    this.ConstantServiceGetValue(key)
                    return true
                } else {
                    return false
                }
            } else {
                return false
            }

        },
    },
    methods: {
        goToUrl(url) {
            this.$router.push(url)
        },
        getUserGuide() {

            let config = {
                params: { language: this.$i18n.locale },
                paramsSerializer: (params) => qs.stringify(params, { encode: false })
            }

            DashboardService.getUserGuide(config)
                .then(response => {
                    this._downloadFile(response, 'BAU-' + this.$t('file_name_student_information_system') + '-' + this.$t('file_name_user_guide') + '.pdf')
                })
                .catch(e => {
                    this.$toast.error(this.$t('api.' + e.data.message));
                })
        },

        continueProcess() {
            StudentProgramService.approveKvkk()
                .then(() => {
                    this.$toast.success(this.$t('proccess_success'));
                    this.$refs.kvkk1Modal.$refs.commonModal.hide()
                    this.userStore.kvkk_status = true
                    this.$store.dispatch("auth/restoreUserInfo", this.userStore);
                })
                .catch(err => this.showErrors(err))
        },

        kvkkCheck() {
            this.$store.dispatch('auth/getUser').then(() => {
                let store = (Base.LocalStorage.get('user') || {});
                this.userStore = store
                if (!store.kvkk_status && store.student != null) {
                    this.kvkkChosenContent = ['YL', 'D'].includes(this.userStore.active_student_program.program_level) ? this.$t('kvkk_content') : this.$t('kvkk_lisans_content')
                    this.$refs.kvkk1Modal.$refs.commonModal.show()
                }

            });
        },
        async getStudentApplicationStatus() {
            const response = await StudentService.studentApplicationStatus().catch((e) => {
                if (e.data.message) {
                    this.$toast.error(this.$t("api." + e.data.message));
                }
            });
            this.applicationButtons = response.data.data.filter(item => item.status)
        },
        ConstantServiceGetValue(text) {
            const config = {
                params: {
                    key: text
                },
                paramsSerializer: (params) => qs.stringify(params, { encode: false })
            };
            ConstantService.getValue(config).then(response => {
                this.doubleMajorText = response.data.data
            }).catch(e => {
                this.showErrors(e, null)
            })
        }

    }
}
</script>

<style scope>
a,
a:hover {
    color: #141414;
}
</style>
